html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;

  padding: 0;

  border: 0;

  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";

  content: none;
}

table {
  border-collapse: collapse;

  border-spacing: 0;
}

* {
  font-family: "Outfit", sans-serif;
}

.slider_Sec {
  background: url("../public/assets/images/headerBg.webp");

  background-position: center;

  background-size: cover;

  background-repeat: no-repeat;

  height: auto;

  position: relative;

  overflow: hidden;

  padding: 5rem 0;
}

.HeaderContent {
  display: flex;

  flex-direction: column;

  height: auto;

  justify-content: center;
}

.orderForm {
  display: flex;

  flex-direction: column;

  height: auto;

  justify-content: center;

  position: relative;

  /*overflow: hidden;*/

  z-index: 1;

  position: relative;
}

.formdetails {
  background: #ffffff6b;

  padding: 1rem 2rem;

  border-top-left-radius: 17px;

  border-top: 25px solid #002365;

  border-left: 25px solid #ffd117;

  border-top-right-radius: 60px;
}

.HeaderImg {
  position: absolute;

  top: 1rem;

  right: 0;
}

.HeaderImg img {
  width: 473px;

  height: 100%;
}

.formdetails p {
  padding: 1rem 0;
}

.orderForm p {
  font-size: 2rem;

  color: #fff;

  font-weight: 800;

  text-align: center;
}

.orderForm .form-control {
  height: auto;

  border: none;

  border-radius: 30px;

  padding: 1rem 1rem;
}

.orderForm button {
  width: 100%;

  border-radius: 25px;

  background: #002365;

  border: none;
}

.HeaderContent h1 {
  font-size: 3.6rem;

  color: #fff;

  font-weight: 800;
}

.HeaderContent p {
  font-size: 20px;

  color: #fff;
}

.HeaderContent ul.listitem {
  padding-bottom: 2.1rem;
}
.HeaderContent ul.footerBtn {
  display: flex;

  gap: 13px;

  align-items: baseline;
}

.HeaderContent ul.listitem li {
  color: #fff;

  padding: 10px 0;

  font-size: 20px;
}

.HeaderContent ul.listitem li img {
  width: 25px;

  vertical-align: top;

  margin-right: 6px;
}

.HeaderContent ul.footerBtn li a {
  padding: 0.6rem 2.2rem;

  color: #fff;

  text-decoration: none;

  font-size: 1.2rem;
}

.HeaderContent ul.footerBtn li a.ordernow {
  background: #002365;

  border-radius: 46px;
}

.HeaderContent ul.footerBtn li a.livechat {
  background: #ffd117;

  border-radius: 46px;

  color: #000;
}

.HeaderContent ul.footerBtn li a.whatsapp {
  padding-left: 1rem;
}

.HeaderContent ul.footerBtn li a img {
  width: 24px;

  vertical-align: middle;
}

.whychoose {
  padding: 5rem 0;

  background: url("../public/assets/images/whychoosebg.webp");

  background-size: cover;

  background-position: center;

  background-repeat: no-repeat;
}

.trendingcourse {
  display: flex;

  gap: 10px;

  padding: 0.8rem 0;
}

.whychoose span {
  padding-bottom: 1.4rem;

  padding-top: 2rem;

  font-size: 3rem;

  color: #002365;

  font-weight: 800;
}

.trendingimg {
  padding: 0.3rem 0;
}

.trendingContent {
}

.trendingContent b {
  font-size: 1.6rem;

  color: #002365;

  padding-bottom: 5px;

  font-weight: 700;
}

.trendingContent p {
  line-height: 24px;

  font-size: 19px;
}

.CounterImg {
  background: url("../public/assets/images/countershape.webp");

  background-size: cover;

  background-position: center;

  background-repeat: no-repeat;

  width: 128px;

  height: 125px;

  display: flex;

  justify-content: center;

  align-items: center;

  align-content: center;

  margin: auto;
}

.CounterImg p {
  font-size: 1.5rem;

  color: #fff;

  font-weight: 800;
}

.counterSec {
  padding: 2rem 0;
}

.counterBox h4 {
  text-align: center;

  padding: 0.5rem 0;
}

.AssignmentCOntentSec {
  padding: 3rem 0;

  padding-bottom: 5rem;
}

.title_Box {
  background: #002365;

  padding: 0.7rem 1.5rem;

  border-top-left-radius: 15px;

  border-top-right-radius: 15px;
}

.title_Box span {
  color: #fff;

  font-size: 1.3rem;
}

.WrapCOntent {
  background: #fff;

  border-top-left-radius: 15px;

  border-top-right-radius: 15px;
}

.WrapCOntent p {
  line-height: 28px;

  padding: 0.8rem;

  font-size: 17px;
}

.ContentBox {
  position: relative;

  z-index: 1;
}

.ContentBox img.CounterShape {
  position: absolute;

  top: 10px;

  left: -50px;

  z-index: -2;
}

.ContentBox img.CounterShapeBig {
  position: absolute;

  bottom: -73px;

  right: -83px;

  z-index: -2;
}

.StepSec {
  padding: 5rem 0;

  background: url("../public/assets/images/stepsBg.webp");

  background-position: right;

  background-size: cover;

  background-repeat: no-repeat;

  position: relative;
}

.StepSec:before {
  content: "";

  position: absolute;

  bottom: 188px;

  width: 100%;

  height: 1px;

  background: #ffffff3b;

  left: 0;

  right: 0;

  max-width: 977px;

  margin: auto;
}

.StepSec h3 {
  color: #fff;

  font-size: 3rem;

  text-align: center;

  font-weight: 800;

  padding-bottom: 3rem;
}

.StepShape {
  width: 90px;

  height: 90px;

  background: #fff;

  margin: auto;

  display: flex;

  align-items: center;

  justify-content: center;

  align-content: center;

  transform: rotate(45deg);

  margin-bottom: 1rem;

  border-radius: 6px;

  border-bottom: 7px solid #fbce19;

  border-right: 7px solid #521dff;

  transition: 0.2s linear;
}

.StepShape.active {
  background: #fbce19;

  border: none;
}

.StepShape:hover {
  background: #fbce19;

  border: none;
}

.StepWrap {
}

.StepWrap span {
  text-align: center;

  padding: 0.6rem 0;

  color: #fff;

  font-size: 1.5rem;

  display: block;
}

.StepShape p {
  transform: rotate(-42deg);

  font-size: 2rem;

  color: #002365;

  font-weight: 600;
}

.mainresearc h2 {
  font-weight: 800;

  text-align: center;

  padding-bottom: 10px;

  font-size: 2.6rem;

  color: #002365;
}

.mainresearc p {
  text-align: center;

  line-height: 24px;

  font-size: 18px;

  padding-bottom: 2rem;
}

.researchtype {
  padding: 1rem 0;
  padding-bottom: 0;
}

.researchtype .container {
  background: #f8f8f8;

  border-top: 45px solid #521dff;

  border-left: 45px solid #002365;

  padding: 1.7rem 3rem;

  border-top-left-radius: 45px;

  border-bottom-right-radius: 0px;

  border-right: 45px solid #fbce19;

  border-top-right-radius: 45px;
}

.researchinfo h5 {
  padding: 1rem 0;

  font-weight: 600;

  font-size: 1.9rem;

  color: #002365;

  line-height: 36px;
}

.researchinfo p {
  text-align: left;

  line-height: 28px;
}

.mainresearc {
  padding: 2rem 0;
  padding-bottom: 0;
}

.featureMake {
  padding: 5rem 0;

  background: url("../public/assets/images/featuresBg.webp");

  background-size: cover;

  background-position: center;

  background-repeat: no-repeat;
}

.featureMake h3 {
  text-align: center;

  padding-bottom: 2.6rem;

  font-size: 3rem;

  font-weight: 800;

  color: #002365;
}

.featureWrap {
  display: flex;

  gap: 10px;

  padding: 0.8rem 0;
}

.featureContent span {
  font-size: 1.8rem;

  padding-top: 10px;

  display: block;

  padding-bottom: 8px;

  font-weight: 600;

  color: #000;
}

.featureContent p {
  font-size: 17px;
}

.caterStudent {
  padding: 3rem 0;
}

.caterStudent h4 {
  font-size: 2.5rem;

  text-align: center;

  font-weight: 800;

  color: #002365;

  padding-bottom: 2rem;
}

.UniLogo {
}

.UniLogo img {
  width: 100%;
}

.caterStudent .swiper-pagination {
  position: initial;

  padding: 1.5rem 0;
}

.caterStudent .swiper-pagination span {
  width: 20px;

  height: 20px;

  background: #002365;
}

.satisfy_section {
  position: relative;

  background-color: #002365;

  padding: 3rem 0px;
}

.satisfy_section h4 {
  font-size: 3rem;

  color: #fff;

  font-weight: 800;

  text-align: center;

  padding-bottom: 3rem;
}

.satisfy_section h5 {
  font-size: 3rem;

  color: #fff;

  font-weight: 800;

  text-align: center;

  padding-bottom: 3rem;
}

.tick-list li {
  font-size: 1.3rem;

  font-weight: 400;

  color: #fff;

  line-height: 41px;
}

.tick-list li i {
  color: #ffd117;
}

.calltoacton {
  padding: 2rem 0;

  background: #fbce19;
}

.CallWrap {
  display: flex;

  gap: 7px;

  position: relative;
}

.CallWrap:before {
  position: absolute;

  content: "";

  top: 0;

  left: 0;

  z-index: -1;

  width: 100%;

  height: 100%;

  background: #b8b8b833;

  border-radius: 53px;

  border-top-left-radius: 53px;

  border-bottom-left-radius: 53px;
}

.callinfo {
  padding: 1rem 0;
}

.callinfo p {
  font-size: 15px;
}

.callinfo a {
  text-decoration: none;

  font-weight: 800;

  color: #002365;

  font-size: 17px;
}

.studentTest {
  padding: 3rem 0;
}

.testionial_sec {
  text-align: center;
}

.testionial_sec h5 {
  font-size: 2rem;

  font-weight: 600;
}

.testionial_sec h5 {
  font-size: 3rem;

  font-weight: 800;

  color: #002365;

  padding-bottom: 3rem;
}

.footerStd {
  display: flex;

  gap: 10px;

  padding: 1rem 0;
}

.infoStd {
  padding: 1rem 0;
}

.infoStd span {
  font-size: 1.25rem;
}

.rating {
  color: #fbce19;

  font-size: 1.5rem;

  padding-bottom: 1rem;
}

.StuWrap p {
  font-size: 18px;

  line-height: 26px;
}

.StuWrap {
  padding: 2rem 1.6rem;

  background: #f8f8f8;

  border-top: 22px solid #fbce19;

  border-left: 22px solid #002365;

  border-top-left-radius: 20px;

  border-bottom-right-radius: 48px;
}

.studentTest .swiper-pagination {
  position: initial;

  padding: 1.5rem 0;
}

.studentTest .swiper-pagination span {
  width: 20px;

  height: 20px;

  background: #002365;
}

.amountCounter {
  position: absolute;

  top: -29px;

  left: -42px;

  width: 110px;

  height: 110px;

  border-radius: 100%;

  background: #ffd117;

  display: flex;

  justify-content: center;

  align-items: center;

  flex-direction: column;

  border: 7px solid #00236540;
}

.amountCounter h6 {
  font-weight: 600;

  font-size: 1.3rem;

  color: #002365;
}

.amountCounter p {
  font-weight: 800;

  font-size: 1.4rem;
}

.header_Sec {
  background: #4069b8;
}

.header_Sec .navbar-light .navbar-nav .nav-link {
  color: #fff;

  font-size: 1.2rem;

  font-weight: 100;
}

.header_Sec .navbar-light .navbar-nav .nav-link.ordernow {
  background: #ffd117;

  color: #002365;

  padding: 0.5rem 1rem;

  border-radius: 19px;

  margin-right: 5px;
}

.header_Sec .navbar-light .navbar-nav .nav-link.livechat {
  background: #002365;

  color: #ffffff;

  padding: 0.5rem 1rem;

  border-radius: 19px;

  animation: move 2s linear infinite;
}

.header_Sec .navbar-nav .dropdown-menu {
  margin: 0;

  padding: 0;

  background: #ffd117;

  border: none;

  border-bottom-right-radius: 86px;

  top: 46px;

  padding-top: 1rem;

  padding-bottom: 3rem;

  border-right: 5px solid #002365;
}

.header_Sec .navbar-nav .dropdown-menu .dropdown-item {
  padding: 0.8rem 1rem;

  color: #000;

  border-bottom: 1px solid #3333;
}

.header_Sec .navbar-nav .dropdown-menu .dropdown-item:hover {
  background: #002365;

  color: #fff;
}

.header_Sec .navbar-nav .dropdown-menu .dropdown-item:nth-last {
  border: none;
}

.footer_Sec {
  padding: 4rem 0;

  background: #ffd117;

  position: relative;

  overflow: hidden;
}

.footer_Sec a img {
  position: relative;
}

.footerInfo {
  padding: 1.4rem 0;
}

.footerInfo b {
  font-size: 1.5rem;

  font-weight: 800;

  padding-bottom: 1rem;

  color: #002365;
  display: block;
}

.footerInfo p {
  font-size: 19px;

  line-height: 29px;

  padding-bottom: 2rem;
}

ul.socialFooter {
  padding: 1.4rem 0;

  text-align: right;
}

ul.socialFooter li {
  display: inline-block;
}

ul.socialFooter li a {
  display: block;

  font-size: 2rem;

  padding: 0 0.5rem;

  color: #333;
}

.footer_Sec:before {
  content: "";

  position: absolute;

  top: -87px;

  width: 196px;

  height: 280px;

  border-right: 50px solid #002365;

  left: 0;

  border-bottom: 50px solid #2aaab7;

  border-bottom-right-radius: 242px;
}

.footerInfo ul {
}

.footerInfo ul li {
  display: block;
}

.footerInfo ul li a {
  display: block;

  color: #000;

  padding: 0.3rem 0;

  font-size: 1.2rem;
  text-decoration: none;
}

.footer_Sec:after {
  content: "";

  position: absolute;

  bottom: -48px;

  width: 246px;

  height: 300px;

  border-right: 50px solid #002365;

  right: 0;

  border-bottom: 50px solid #002365;

  border-bottom-right-radius: 242px;

  transform: rotate(183deg);
}

.emailFot {
  padding: 0.3em 0;
}

.emailFot span {
  font-weight: 800;

  display: block;

  padding-bottom: 0.3rem;
}

.emailFot a {
  text-decoration: none;

  color: #000;
}

#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: none;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 120px;
  height: 120px;
  border-style: solid;
  border-color: #fff;
  border-top-color: #ffd117;
  border-bottom-color: #003c81;
  border-width: 8px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.HeaderInner {
  padding: 4.1rem 0;
  background: url("../public/assets/images/about.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/* Price Page */
.pricingbg {
  padding: 4.1rem 0;
  background: url("../public/assets/images/pricing.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Reviews Page */
.reviewsbg {
  padding: 4.1rem 0;
  background: url("../public/assets/images/reviews.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.cipdbg {
  padding: 4.1rem 0;
  background: url("../public/assets/images/cipd-help.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.dissertationbg {
  padding: 4.1rem 0;
  background: url("../public/assets/images/dissertation-help.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.mbahelpbg {
  padding: 4.1rem 0;
  background: url("../public/assets/images/mba-help.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.essay-bg {
  padding: 4.1rem 0;
  background: url("../public/assets/images/essay-help.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.nursing-bg {
  padding: 4.1rem 0;
  background: url("../public/assets/images/nursing-help.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.HeaderInner h1 {
  font-size: 3.6rem;
  color: #002365;
  font-weight: 800;
  padding-bottom: 1rem;
}

.HeaderInner a {
  padding: 0.7rem 2rem;
  background: #ffd117;
  display: inline-block;
  margin: 1rem 0;
  color: #002365;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  font-weight: 700;
  box-shadow: 2px 2px 2px #787878c9;
  text-decoration: none;
}
.HeaderInner a.ordernow {
  background: #002365;
  color: #fff;
}
.HeaderInner a.livechat {
  background: #ffd117;
  color: #002365;
}

.order_specification {
  padding: 1rem 0;
  text-align: center;
  background: #ffd117;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 10px solid #002365;
}
.order_specification h2 {
  font-weight: 800;
  color: #002365;
}
.info_personal .order_specification {
  background: #002365;
  padding-bottom: 1.3rem;
  border-bottom: 10px solid #ffd117;
}
.info_personal .order_specification h2 {
  color: #fff;
  padding-top: 1rem;
}

.ordersummary {
  position: sticky;
  top: 163px;
  border: 1px solid #3333;
  padding: 20px 35px;
  border-radius: 20px;
  background: #f9f9f9;
  margin: 12px 0;
}

.ordersummary h3 {
  font-size: 22px;
  margin: 0;
  text-align: center;
  padding-bottom: 15px;
  font-weight: 700;
}

.ordersummary hr {
  margin: 8px 0;
}

.academic_paper h4 {
  font-size: 16px;
  color: #000;
  font-weight: 700;
}

.academic_paper ul {
  margin: 0;
  list-style: none;
}

.academic_paper ul li {
  font-size: 14px;
  padding: 5px 0;
  display: block;
}

.basic_price {
  width: 100%;
  padding: 15px 0;
}

.basic_price h4 {
  font-size: 16px;
  color: #000;
  font-weight: 700;
}

.basic_price ul {
  margin: 0;
  list-style: none;
}

.basic_price ul li {
  font-size: 14px;
  padding: 5px 0;
  display: block;
}

.basic_price ul li.borderdata {
  border-top: 1px solid #dee2e6;
}

.form_order .form-control {
  height: auto;
  border: none;
  background: #f0f0f0;
  padding: 0.6rem 1rem;
}

.form_order .form-group label {
  padding-bottom: 0.5rem;
}

.bgGif {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 100%;
  align-content: center;
  align-items: center;
}
.bgGif img {
  width: 56%;
  height: 242px;
  opacity: 0.2;
}

.middleFotter img {
  width: 100%;
  max-width: 402px;
  margin: auto;
  display: block;
}

.AssignmentCOntentSec h2 {
  font-size: 2.4rem;
  text-align: center;
  font-weight: 800;
  padding-bottom: 1rem;
  color: #002365;
}

.AssignmentCOntentSec b {
  padding-bottom: 2rem;
  text-align: center;
  font-size: 2rem;
  color: #2e74b2;
  font-weight: 500;
  display: block;
}

.section_bold {
  padding: 50px 0px;
}
.section_bold h1 {
  margin: 0;
  padding: 0;
  font-size: 39px;
  color: #002365;
}
.section_bold h2 {
  padding-bottom: 17px;
  color: #002365;
  padding-top: 8px;
  font-weight: 600;
  line-height: initial;
}
.section_bold p {
  line-height: 26px;
  text-align: justify;
  font-size: 15px;
  padding-bottom: 19px;
}
.section_bold ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.section_bold ul li {
  padding: 7px 0;
}

.form-desktopInner {
  margin-bottom: 20px;
}

.form-desktopInner p {
  background: #002365;
  border-radius: 4px;
  color: #fff;
  margin: 0;
  font-size: 20px !important;
  padding: 8px 13px;
  padding: 13px 0 !important;
  text-align: center !important;
  font-weight: 800;
}

.form-desktopInner form {
  padding: 15px;
  background: #ffd117;
  width: 100%;
}

.form-desktopInner input[type="text"],
.form-desktopInner input[type="email"],
.form-desktopInner input[type="tel"],
.form-desktopInner input[type="number"],
.form-desktopInner select {
  border: 1px solid #999;
  height: 35px;
  border-radius: 4px;
  padding-left: 10px;
  margin-top: 8px;
  width: 100%;
}

.form-desktopInner input[type="submit"] {
  width: 100%;
  height: 30px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  background: #002365;
  border: none;
  font-size: 13px;
  border-radius: 4px;
  letter-spacing: 1px;
  margin-top: 7px;
}

.form-desktopInner input[type="submit"]:hover {
  background: #002365;
  transition: all 0.5s ease-out;
}

.first_sidebar {
  margin: 10px 0;
}

.side-bnrs a img {
  width: 100%;
}
.side-bnrs a {
  display: block;
  padding: 10px 0px;
}
.topsidebar {
  background: #003c81;
  text-align: center;
  color: #fff;
  padding: 10px 0;
}
.topsidebar p {
  font-size: 19px !important;
  text-align: center !important;
  padding: 6px 0 !important;
  font-weight: 700;
}
.topsidebar span {
  font-size: 20px !important;
  color: #ffd117;
}

.firstimg img {
  width: 100%;
}

.bottom_sidebar {
  background: #036ce4;
  text-align: center;
  padding: 10px 0;
}

.bottom_sidebar p {
  font-size: 26px !important;
  color: #fff;
  text-align: center !important;
  padding: 5px 0 !important;
  font-weight: 700;
}
.bottom_sidebar span {
  font-size: 24px;
  color: #97fc00;
  font-weight: 600;
}
.bottom_sidebar a {
  display: inline-block;
  background: #ffd117;
  padding: 3px 16px;
  border-radius: 4px;
  margin: 10px 0;
  color: #000;
  text-decoration: none;
}

.content-inner {
  line-height: 24px;
  background: #fff;
  margin: 0 auto 50px;
  box-shadow: 0 0 1px #666;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 19px 4px 30px;
  color: #454545;
  font-size: 16px;
}

.content-inner-main .heading2 {
  font-size: 2.5rem;
  margin: 0;
  color: #002365;
  font-weight: 800;
  line-height: initial;
  padding: 1rem 0;
}

.inner-content p {
  margin-bottom: 10px;
  font-size: 1.2rem;
  line-height: 28px;
}

.content-inner-main .heading3 {
  font-size: 1.8rem;
  margin: 0;
  color: #2aaab7;
  font-weight: 600;
  padding: 1rem 0;
}

.inner-content b {
  font-size: 1.4rem;
  padding: 1rem 0;
  display: block;
  color: #002365;
  padding-bottom: 1.6rem;
  font-weight: 800;
}

.OurProcessTxt {
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.last-heading {
  font-size: 1.8rem;
  margin: 0;
  color: #2aaab7;
  font-weight: 600;
}

.OurProcessTxt li {
  display: inline-block;
  width: 271px;
  padding: 0.2rem 0;
}

.inner-content h2 {
  font-size: 2.3rem;
  color: #2aaab7;
  padding: 1rem 0;
  font-weight: 700;
}

.inner-content h3 {
  font-size: 2.3rem;
  color: #2aaab7;
  padding: 1rem 0;
  font-weight: 700;
}

.inner-content h4 {
  font-size: 2.3rem;
  color: #2aaab7;
  padding: 1rem 0;
  font-weight: 700;
}

ul.innerpagelist li {
  font-size: 1.2rem;
  line-height: 28px;
}

.section_pricing {
  padding: 50px 0px;
}
.section_pricing h1 {
  margin: 0;
  padding: 0;
  font-size: 27px;
  color: #002365;
  font-weight: 700;
}
.section_pricing h3 {
  padding-bottom: 17px;
  color: #002365;
  padding-top: 8px;
  font-weight: 700;
}
.section_pricing p {
  line-height: 26px;
  text-align: justify;
  font-size: 15px;
  padding-bottom: 19px;
}
.section_pricing .merge_btn {
  text-align: center;
}
.section_pricing .merge_btn a {
  display: inline-block;
  padding: 10px 33px;
  background: #002365;
  color: #fff;
  border-radius: 6px;
  text-decoration: none;
  outline: none;
}
.section_pricing h4 {
  padding: 20px 0px;
}

.table-pricing thead {
  border: none;
  background-color: #002365;
  color: #fff;
  /* vertical-align: middle; */
  /* text-align: center; */
}
.table td,
.table th {
  padding: 19px 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table-pricing thead {
  border: none;
  background-color: #002365;
  color: #fff;
  /* vertical-align: middle; */
  /* text-align: center; */
}
.table th {
  background: #002365;
  color: #fff;
}

.section_reviews {
  padding: 50px 0px;
}
.section_reviews h1 {
  text-align: center;
  color: #002365;
  font-weight: 700;
}

.item_review {
  width: 100%;
  padding: 30px 0px;
  overflow: hidden;
}
.box_review {
  width: 100%;
  box-shadow: 2px 0px 13px #3333;
  border-bottom-left-radius: 31px;
  padding: 10px 14px;
  margin-bottom: 20px;
}

.box_review ul {
  list-style: none;
  padding: 20px 0px;
}
.box_review ul li {
  display: inline-block;
  margin: 10px 0px;
}
.box_review ul li i {
  color: #ffd117;
  font-size: 17px;
}

.client_x {
  padding: 20px 0px;
  text-align: center;
  background: #002365;
  border-bottom-left-radius: 31px;
  border-top-right-radius: 31px;
  color: #fff;
}

button.show-more {
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 20px;
  background: #002365;
  color: #fff;
  display: block;
  margin: auto;
}

@keyframes move {
  0% {
    transform: scale(0.8);
  }
  30% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: scale(0.4);
    transform: rotate(0);
  }
}

.footerInfo.MySer {
  width: 204px;
  display: inline-table;
}

.footerInfo ul.policydata li {
  display: inline-block;
}
.footerInfo ul.policydata li a {
  font-size: 1.1rem;
  padding: 0.3rem 0.2rem;
}

.make-me-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 111px;
}

p.coreclass {
  font-size: 23px;
  font-weight: 600;
  color: #000;
}

.copyrightxx {
  background: #002365;
  padding: 10px 0;
}
.copyrightxx p {
  color: #fff;
}

.FiveStarBox a {
  display: block;
  transition: 0.2s linear;
}

.FiveStarBox a img {
  width: 100%;
  max-width: 220px;
  margin: auto;
  display: block;
}

.RatingReviews {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.ReviewItems {
  width: 144px;
  background: #efefef;
  border-radius: 15px;
  padding: 8px 18px;
  height: 59px;
  transition: 0.2s linear;
}

.ReviewItems a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.FiveStarBox a:hover {
  transform: scale(1.1);
}

.ReviewItems:hover {
  transform: scale(1.1);
}

.cv-header {
  background-image: url("../public/assets/images/cv-header.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 3rem 0px;
}

.cv-header .ordernow {
  background: #ffd117 !important;
  border-radius: 46px !important;
  color: #000 !important;
}

.cv-header .livechat {
  background: #4069b8 !important;
  color: #fff !important;
}

.cv-header input {
  padding: 8px;
  width: 100%;
  border-radius: 41px;
  border: 1px solid #fff;
}

.cv-header select {
  padding: 8px;
  width: 100%;
  border-radius: 41px;
  border: 1px solid #fff;
}

.cv-section {
  padding: 3rem 0px;
}

.cv-section h3 {
  font-size: 33px;
}

.cv-section p {
  line-height: 28px;
  padding: 0.8rem;
  font-size: 17px;
}

.cv-contact {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.cv-call {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
  margin-right: 15px;
  margin-top: 13px;
}

.cv-call a {
  color: #000;
  font-size: 18px;
  padding-left: 10px;
}

.cv-btn a {
  background: #ffd117 !important;
  border-radius: 46px !important;
  color: #000 !important;
  padding: 0.6rem 2.2rem;
  text-decoration: none;
  font-size: 1.2rem;
}

.cv-portfolio {
  background-image: url("../public/assets/images/cv-portfolio.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3rem 0px;
}

.cv-portfolio h4 {
  font-size: 34px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.cv-portfolio p {
  text-align: center;
  color: #fff;
  padding-bottom: 15px;
  line-height: 24px;
  padding: 0.8rem;
  font-size: 17px;
}
.form-group {
  margin-bottom: 1rem;
}
.form-control {
  appearance: auto;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.fixedbtnwhatsapp {
  position: fixed;
  bottom: 1em;
  left: 2em;
  z-index: 9999999;
}
.header_Sec.active {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  background: #4069b8;
}
.info_personal button {
  background: #ffd117;
  color: #002365;
  padding: 0.5rem 1rem;
  border-radius: 19px;
  margin-right: 5px;
}
