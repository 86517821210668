@media (min-width: 1344px) and (max-width: 1367px) {
  .slider_Sec .col-md-8 {
    max-width: 54.666667%;
  }
}

@media (min-width: 1281px) {
}
@media (min-width: 1025px) and (max-width: 1280px) {
}
@media (min-width: 1024px) and (max-width: 1366px) {
}
@media (min-width: 768px) and (max-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
@media (min-width: 481px) and (max-width: 767px) {
  ul {
    padding-left: 0 !important;
  }
  .ContentBox img.CounterShapeBig {
    position: absolute;
    bottom: -73px;
    right: -15px;
    z-index: -2;
    width: 200px;
  }

  .HeaderContent h1 {
    font-size: 2.5rem;
    color: #fff;
    font-weight: 800;
  }

  .slider_Sec {
    padding: 3rem 0;
  }

  .orderForm {
    margin-top: 6rem;
  }

  .HeaderImg {
    display: none;
  }
  .HeaderContent ul.footerBtn li a {
    display: block;
  }

  .HeaderContent ul.footerBtn li {
    min-width: 140px;
    display: inline-block;
    position: relative;
  }

  .HeaderContent ul.footerBtn {
    display: block;
    gap: 13px;
    align-items: baseline;
  }

  .HeaderContent ul.footerBtn li a.whatsapp {
    padding-left: 1rem;
    padding-top: 1rem;
    font-size: 1.9rem;
    margin-top: 3.6rem;
  }

  .navbar-light .navbar-toggler {
    color: #002365;
    border-color: transparent;
    font-size: 2.4rem;
    padding-top: 1rem;
  }

  .whychoose h4 {
    padding-bottom: 1.4rem;
    padding-top: 0rem;
    font-size: 2.4rem;
    color: #002365;
    font-weight: 800;
  }

  .whychoose {
    padding: 3rem 0;
  }

  .AssignmentCOntentSec h3 {
    font-size: 1.6rem;
    text-align: center;
    font-weight: 800;
    padding-bottom: 1rem;
    color: #002365;
    line-height: initial;
  }

  .AssignmentCOntentSec h5 {
    padding-bottom: 2rem;
    text-align: center;
    font-size: 1.3rem;
    color: #2e74b2;
  }

  .WrapCOntent {
    background: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 2px 2px 2px #3333;
    margin: 1rem 0;
  }

  .StepSec h3 {
    color: #fff;
    font-size: 1.8rem;
    text-align: center;
    font-weight: 800;
    padding-bottom: 3rem;
    line-height: initial;
  }

  .StepWrap {
    margin: 1rem 0;
  }

  .mainresearc h4 {
    font-weight: 600;
    text-align: center;
    padding-bottom: 10px;
    font-size: 1.7rem;
    color: #002365;
    line-height: initial;
  }

  .researchtype .container {
    background: #f8f8f8;
    border-top: 28px solid #521dff;
    border-left: 28px solid #002365;
    padding: 0.3rem 0.6rem;
    border-top-left-radius: 45px;
    border-bottom-right-radius: 0px;
    border-right: 28px solid #fbce19;
    border-top-right-radius: 45px;
  }

  .featureMake h4 {
    text-align: center;
    padding-bottom: 1.8rem;
    font-size: 1.8rem;
    font-weight: 800;
    color: #002365;
  }

  .featureContent h5 {
    font-size: 1.5rem;
    padding-top: 10px;
    padding-bottom: 5px;
    font-weight: 600;
    color: #000;
  }

  .caterStudent h4 {
    font-size: 2rem;
    text-align: center;
    font-weight: 800;
    color: #002365;
    padding-bottom: 2rem;
  }

  .satisfy_section h4 {
    font-size: 1.8rem;
    color: #fff;
    font-weight: 800;
    text-align: center;
    padding-bottom: 3rem;
    line-height: initial;
  }

  .callinfo a {
    text-decoration: none;
    font-weight: 800;
    color: #002365;
    font-size: 16px;
  }

  .testionial_sec h3 {
    font-size: 2rem;
    font-weight: 800;
    color: #002365;
    padding-bottom: 3rem;
    padding-top: 1rem;
    line-height: initial;
  }

  .testionial_sec b {
    font-size: 1.3rem;
    line-height: initial;
  }

  .researchtype .row {
    height: 450px;
    overflow: scroll;
  }

  .footer_Sec:before {
    content: "";
    position: absolute;
    top: -1px;
    width: 270px;
    height: 235px;
    left: 0;
    border-bottom-right-radius: 178px;
    background: #fff;
    border-right: 21px solid #ffd117;
    border-bottom: 21px solid #ffd117;
    box-shadow: 2px 2px 2px #0000000d;
  }

  ul.socialFooter {
    padding: 1.4rem 0;
    text-align: left;
    margin-top: 6rem;
    padding-bottom: 0;
  }

  .footer_Sec:after {
    content: "";
    position: absolute;
    bottom: -48px;
    width: 188px;
    height: 168px;
    border-right: 50px solid #002365;
    right: 0;
    border-bottom: 50px solid #002365;
    border-bottom-right-radius: 242px;
    transform: rotate(183deg);
  }

  .footerInfo p {
    font-size: 19px;
    line-height: 29px;
    padding-bottom: 0rem;
  }

  .header_Sec .navbar-light .navbar-nav .nav-link.ordernow {
    margin: 0.6rem 0;
  }

  .header_Sec {
    background: #fff;
  }

  .header_Sec .navbar-light .navbar-nav .nav-link {
    color: #002365;
    font-size: 1.4rem;
    font-weight: 100;
  }

  .AssignmentCOntentSec {
    padding: 0rem 0;
    padding-bottom: 5rem;
  }

  .StepSec:before {
    display: none;
  }

  .HeaderContent ul.footerBtn li a.ordernow {
    background: #002365;
    border-radius: 46px;
    margin-right: 8px;
  }

  .amountCounter {
    position: absolute;
    top: -49px;
    left: -1px;
    width: 110px;
    height: 110px;
    border-radius: 100%;
    background: #ffd117;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 7px solid #8fa7d3;
    border-bottom-left-radius: 0;
    transform: rotate(-1deg);
  }

  .HeaderContent ul.footerBtn li img.arrowFound {
    position: absolute;
    top: 19px;
    right: 0px;
    width: 122px;
  }

  .HeaderInner h1 {
    font-size: 1.7rem;
    color: #002365;
    font-weight: 800;
    padding-bottom: 1rem;
    line-height: initial;
  }

  .section_reviews h1 {
    text-align: center;
    color: #002365;
    font-size: 1.5rem;
  }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  .ContentBox img.CounterShapeBig {
    position: absolute;
    bottom: -73px;
    right: -15px;
    z-index: -2;
    width: 200px;
  }

  .HeaderContent h1 {
    font-size: 2.5rem;
    color: #fff;
    font-weight: 800;
  }
  .slider_Sec {
    padding: 3rem 0;
  }

  .orderForm {
    margin-top: 6rem;
  }

  .HeaderImg {
    display: none;
  }

  .HeaderContent ul.footerBtn li a {
    display: block;
  }

  .HeaderContent ul.footerBtn li {
    min-width: 140px;
    display: inline-block;
    position: relative;
  }

  .HeaderContent ul.footerBtn {
    display: block;
    gap: 13px;
    align-items: baseline;
  }

  .HeaderContent ul.footerBtn li a.whatsapp {
    padding-left: 1rem;
    padding-top: 1rem;
    font-size: 1.9rem;
    margin-top: 3.6rem;
  }

  .navbar-light .navbar-toggler {
    color: #002365;
    border-color: transparent;
    font-size: 2.4rem;
    padding-top: 1rem;
  }

  .whychoose h4 {
    padding-bottom: 1.4rem;
    padding-top: 0rem;
    font-size: 2.4rem;
    color: #002365;
    font-weight: 800;
  }

  .whychoose {
    padding: 3rem 0;
  }

  .AssignmentCOntentSec h3 {
    font-size: 1.6rem;
    text-align: center;
    font-weight: 800;
    padding-bottom: 1rem;
    color: #002365;
    line-height: initial;
  }

  .AssignmentCOntentSec h5 {
    padding-bottom: 2rem;
    text-align: center;
    font-size: 1.3rem;
    color: #2e74b2;
  }

  .WrapCOntent {
    background: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 2px 2px 2px #3333;
    margin: 1rem 0;
  }

  .StepSec h3 {
    color: #fff;
    font-size: 1.8rem;
    text-align: center;
    font-weight: 800;
    padding-bottom: 3rem;
    line-height: initial;
  }

  .StepWrap {
    margin: 1rem 0;
  }

  .mainresearc h4 {
    font-weight: 600;
    text-align: center;
    padding-bottom: 10px;
    font-size: 1.7rem;
    color: #002365;
    line-height: initial;
  }

  .researchtype .container {
    background: #f8f8f8;
    border-top: 28px solid #521dff;
    border-left: 28px solid #002365;
    padding: 0.3rem 0.6rem;
    border-top-left-radius: 45px;
    border-bottom-right-radius: 0px;
    border-right: 28px solid #fbce19;
    border-top-right-radius: 45px;
  }

  .featureMake h4 {
    text-align: center;
    padding-bottom: 1.8rem;
    font-size: 1.8rem;
    font-weight: 800;
    color: #002365;
  }

  .featureContent h5 {
    font-size: 1.5rem;
    padding-top: 10px;
    padding-bottom: 5px;
    font-weight: 600;
    color: #000;
  }

  .caterStudent h4 {
    font-size: 2rem;
    text-align: center;
    font-weight: 800;
    color: #002365;
    padding-bottom: 2rem;
  }

  .satisfy_section h4 {
    font-size: 1.8rem;
    color: #fff;
    font-weight: 800;
    text-align: center;
    padding-bottom: 3rem;
    line-height: initial;
  }

  .callinfo a {
    text-decoration: none;
    font-weight: 800;
    color: #002365;
    font-size: 16px;
  }

  .testionial_sec h3 {
    font-size: 2rem;
    font-weight: 800;
    color: #002365;
    padding-bottom: 3rem;
    padding-top: 1rem;
    line-height: initial;
  }

  .testionial_sec b {
    font-size: 1.3rem;
    line-height: initial;
  }

  .researchtype .row {
    height: 450px;
    overflow: scroll;
  }

  .footer_Sec:before {
    content: "";
    position: absolute;
    top: -1px;
    width: 270px;
    height: 235px;
    left: 0;
    border-bottom-right-radius: 178px;
    background: #fff;
    border-right: 21px solid #ffd117;
    border-bottom: 21px solid #ffd117;
    box-shadow: 2px 2px 2px #0000000d;
  }

  ul.socialFooter {
    padding: 1.4rem 0;
    text-align: left;
    margin-top: 6rem;
    padding-bottom: 0;
  }

  .footer_Sec:after {
    content: "";
    position: absolute;
    bottom: -48px;
    width: 188px;
    height: 168px;
    border-right: 50px solid #002365;
    right: 0;
    border-bottom: 50px solid #002365;
    border-bottom-right-radius: 242px;
    transform: rotate(183deg);
  }

  .footerInfo p {
    font-size: 19px;
    line-height: 29px;
    padding-bottom: 0rem;
  }

  .header_Sec .navbar-light .navbar-nav .nav-link.ordernow {
    margin: 0.6rem 0;
  }

  .header_Sec {
    background: #fff;
  }

  .header_Sec .navbar-light .navbar-nav .nav-link {
    color: #002365;
    font-size: 1.4rem;
    font-weight: 100;
  }

  .AssignmentCOntentSec {
    padding: 0rem 0;
    padding-bottom: 5rem;
  }

  .StepSec:before {
    display: none;
  }

  .HeaderContent ul.footerBtn li a.ordernow {
    background: #002365;
    border-radius: 46px;
    margin-right: 8px;
  }

  .amountCounter {
    position: absolute;
    top: -49px;
    left: -1px;
    width: 110px;
    height: 110px;
    border-radius: 100%;
    background: #ffd117;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 7px solid #8fa7d3;
    border-bottom-left-radius: 0;
    transform: rotate(-1deg);
  }

  .HeaderContent ul.footerBtn li img.arrowFound {
    position: absolute;
    top: 19px;
    right: 0px;
    width: 122px;
  }

  .HeaderInner h1 {
    font-size: 1.7rem;
    color: #002365;
    font-weight: 800;
    padding-bottom: 1rem;
    line-height: initial;
  }

  .section_reviews h1 {
    text-align: center;
    color: #002365;
    font-size: 1.5rem;
  }

  ul {
    padding-left: 0 !important;
  }
}
